import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { scroller } from 'react-scroll';

import { PAGES, SCROLL_CONFIG } from 'config';
import { FORMAT_GRAPH_DATA, getImageUrl } from 'utilities';
import { Layout } from 'containers';
import { Banner, FooterBanner } from 'components';
import {
  GuideList,
  InstagramList,
  TourList,
  VideoIntro,
  Review,
} from 'layouts';

const showdown = require('showdown');

const converter = new showdown.Converter();


// const alertMessage = [{ type: 'success', content: '<a href="/discounts">SPRING SALE Save 10% on spring dates on select Born to Roam tours.</a>' }];

const TourCategory = ({ pageContext, data }) => {
  const breadcrumbs = [
    {
      name: PAGES.TOURS.TITLE,
      absolute_slug: PAGES.TOURS.PATH,
    },
    {
      name: pageContext.name,
      absolute_slug: pageContext.absolute_slug,
    },
  ];

  const tourList = pageContext.children.filter(tour => tour.archived === false);

  return (
    <Layout page="tours">
      <Helmet
        title={pageContext.page_title}
        meta={[
          { name: 'description', content: pageContext.meta_description },
          { name: 'og:title', content: pageContext.og_title },
          { name: 'og:description', content: pageContext.og_description },
          {
            name: 'og:image:secure',
            content: getImageUrl({
              id: pageContext.description_picture_id,
              images: data.allTourImages.edges,
              height: 520,
            }),
          },
          {
            name: 'og:image',
            content: getImageUrl({
              id: pageContext.description_picture_id,
              images: data.allTourImages.edges,
              height: 520,
            }),
          },
        ]}
        link={[{ rel: 'canonical', href: `${pageContext.absolute_slug}/` }]}
      />
      <Banner
        backgroundImage={getImageUrl({
          id: pageContext.description_picture_id,
          images: data.allTourImages.edges,
          height: 520,
        })}
        modifier="c-banner--xs"
      >
        <div
          className="c-banner__category-icon"
          dangerouslySetInnerHTML={{ __html: pageContext.category_icon.svg_text }}
        />
        <div className="c-heading c-heading--h0 c-heading--haka">
          {pageContext.name}
        </div>
        <div className="c-button-container">
          <button
            type="button"
            className="c-button c-button--primary c-button--wide"
            onClick={() => scroller.scrollTo(TourList.name, SCROLL_CONFIG)}
          >
            {`View ${pageContext.name} tours`}
          </button>
        </div>
      </Banner>
      <VideoIntro
        videoUrl={pageContext.youtube_link}
        breadcrumbs={breadcrumbs}
        hasPromo={false}
        disableVideoPromo={pageContext.slug === 'born-to-roam'}
        reversed
      >
        <h1 className="c-heading c-heading--h2">
          {pageContext.page_header}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.description_markdown) }}
        />
        {pageContext.inclusions_markdown && (
          <h4 className="c-heading c-heading--h4">What’s included on every tour?</h4>
        )}
        <div
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.inclusions_markdown) }}
        />
      </VideoIntro>
      <TourList
        heading={(
          <h2 className="c-heading c-heading--h2">
            {pageContext.slug === 'new-zealand-winter-tours' && (
              'NZ Snow & Winter Tours'
            )}
            {pageContext.slug === 'new-zealand-mountain-bike-tours' && (
              'NZ Mountain Biking Tours'
            )}
            {pageContext.slug === 'new-zealand-tours' && (
              `Our ${pageContext.name} tours`
            )}
            {pageContext.slug === 'haka-plus-tours' && (
              `Our ${pageContext.name} tours`
            )}
          </h2>
        )}
        list={tourList}
        hideCategoryFilter
      />

      <Review />

      <GuideList
        title={`Meet our ${pageContext.name} guides`}
        list={FORMAT_GRAPH_DATA(data.allGuides).filter(guide => guide[pageContext.name.toLowerCase()])}
        limit={3}
      />

      <InstagramList
        title={pageContext.crowdriff_header}
        subTitle="Want to be featured here? Simply tag your Insta pics with #hakatours or upload your pictures <a href='https://upload.crowdriff.com/haka-tours-guest-images' target='_blank'>here</a>."
        hash={pageContext.crowdriff_code}
      />

      {/* <BlogList */}
      {/* title="What we’ve been up to" */}
      {/* list={[1, 2]} */}
      {/* /> */}

      <FooterBanner />
    </Layout>
  );
};

TourCategory.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allGuides {
      edges {
        node {
          id
          api_id
          name
          adventure
          snow
          mtb
          image_url
          description_markdown
        }
      }
    }

    allTourImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default TourCategory;
